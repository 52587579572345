import React from 'react';
import CharlesMeta from 'components/common/meta/CharlesMeta';
import { Link } from 'gatsby';

import Container from 'react-bootstrap/Container';
import { Layout } from 'components/common';
import Section from 'components/common/Section';
import Image from '../components/common/utils/Image';

const NotFoundPage = () => (
  <Layout
    showBars
    showNewsletterBeforeFooter={false}
  >
    <CharlesMeta
      data={{
        title: 'Page non trouvée',
        description:
                    'Renseignez votre profil médical, téléconsultez une sexologue.',
      }}
    />
    <Section className="section-not-found">
      <Container className="not-found-container">
        <Image filename="character-404-1.svg" alt="women-character" className="women-skirt-404" />
        <div className="not-found-text-div">
          <h1>404</h1>
          <span>
            Oops - La page que tu cherches n'existe pas. Pas de panique tu peux retourner à
            l'accueil.
          </span>
          <Link to="/" className="go-home-button d-none d-lg-flex">
            <span className="first-arrow">→</span>
            Retour à l'accueil
            <span className="second-arrow">←</span>
          </Link>
        </div>
        <Image filename="character-404-2.svg" alt="women-character" className="women-jean-404" />
        <Link to="/" className="go-home-button d-flex d-lg-none">
          <span>→</span>
          Retour à l'accueil
          <span>←</span>
        </Link>
      </Container>
    </Section>
  </Layout>
);

export default NotFoundPage;
